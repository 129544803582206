import { DoubleArrow } from "@mui/icons-material";
import { Box, Button, Fab, Grid, Stack, Typography } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ERROR, SUCCESS, WARNING } from "../../../app/constants/common";
import { TASK_FIELDS } from "../../../app/constants/config/systemTasks";
import { PRIVATE_ROUTES, routeToSingleTask } from "../../../app/constants/routes";
import no_content_img from "../../../asset/images/no_content.png";
import { RenderOnDemandField } from "../../../components/ui/InputUI";
import { EmptyDataVisualComp, GridLabelValueUI, LoadingProgress } from "../../../components/ui/UiUtils";
import { useCreateTaskMutation } from "../../../hooks/ApiHooks";
import { useOptionsForTaskForm } from "../../../hooks/valueProviderHooks";
import { sectionUiLookBase } from "../../../styled/inlineCssStyles";
import { setAlert } from "../../Alerts/slice/alertSlice";
import { findEquipmentById, findStoreByCustomerIdAndStoreId } from "../../AppSettings/appSlice";
import {
  clearAllTaskFormFields,
  selectAllTasksFieldsWithId,
  selectAreRequiredTaskFieldsFilled,
  selectIsConfigDataLoaded,
  selectTaskFieldValue,
  setTasksFormConfig,
} from "../slice/taskSlice";
import { newFormConfig } from "./config/newTaskFormConfig";
import { renderEquipmentDetailsConfig, renderStoreDetailsConfig } from "./config/utilityConfig";


const Section = ({ title, subtitle, children }) => (
  <Stack sx={{ my: 2 }} direction="row" alignItems="center" justifyContent="space-between" gap={2}>
    <Box>
      <Typography variant="h6" fontWeight={500}>
        {title}
      </Typography>
      <Typography color="text.secondary" fontWeight={300}>
        {subtitle}
      </Typography>
    </Box>
    {children}
  </Stack>
);

const CommonBox = ({ component = "div", id = null, children }) => (
  <Box component={component} id={id} sx={{ ...sectionUiLookBase, p: 2, "& .MuiFormControl-root": { m: 0, p: 0 } }} flex={1}>
    {children}
  </Box>
);

const RenderFormField = ({ field }) => {
  const { currentFieldValue, optionsData, isFieldDisabled, onChange } = useOptionsForTaskForm(field.id, field.fieldType, field.valueProviderProps);
  return (
    <GridLabelValueUI
      label={
        field.colSpan === 2 && (
          <div>
            {field.label}{" "}
            {!!field.isRequired && (
              <Box component="span" sx={{ color: "error.main" }}>
                **
              </Box>
            )}
          </div>
        )
      }
      boxContainerProps={{ flexDirection: "column" }}
      labelSx={{ textWrap: "nowrap", fontWeight: 500 }}
      valueSx={{
        "& .MuiSvgIcon-root, & .MuiIcon-root": { color: "primary.main" },
        "& .MuiFormControl-root": { m: 0, minWidth: "350px" },
        "& .MuiInputBase-root": { paddingRight: "14px" },
        my: 1,
      }}
    >
      <RenderOnDemandField
        field={field}
        fieldType={field.fieldType}
        fieldValue={currentFieldValue}
        onChange={onChange}
        isFieldDisabled={isFieldDisabled}
        optionsData={optionsData}
        size="medium"
        label={
          <div>
            {field.label}{" "}
            {!!field.isRequired && (
              <Box component="span" sx={{ color: "error.main" }}>
                **
              </Box>
            )}
          </div>
        }
        disableClearable={field.isRequired}
        getFromValue={field.getFromValue}
        setToValue={field.setToValue}
      />
    </GridLabelValueUI>
  );
};

const NewTaskFormComponent = () => {
  const isConfigLoaded = useSelector(selectIsConfigDataLoaded);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setTasksFormConfig(newFormConfig.map(({ id, isRequired, value, isEditable }) => ({ id, isRequired, value, isEditable }))));
    return () => dispatch(clearAllTaskFormFields());
  }, [dispatch]);

  return (
    <Stack flex={1}>
      <Section
        title="Add New Task"
        subtitle={
          <Fragment>
            All fields marked with{" "}
            <Box component="em" sx={{ color: "error.main" }}>
              &apos;**&apos;
            </Box>
            &nbsp; are to be filled completely. Please call support for any assistance
          </Fragment>
        }
      />
      <CommonBox component="form" id="add-new-task-form">
        <Grid spacing={1} container>
          {!!isConfigLoaded &&
            newFormConfig.map((field) => (
              <Grid item xs={12} key={field.id}>
                <RenderFormField field={field} />
              </Grid>
            ))}
        </Grid>
      </CommonBox>
    </Stack>
  );
};

const StoreDetails = () => {
  const customerId = useSelector((state) => selectTaskFieldValue(state, TASK_FIELDS.CUSTOMER.ID));
  const storeId = useSelector((state) => selectTaskFieldValue(state, TASK_FIELDS.STORE.ID));
  const store = useSelector((state) => findStoreByCustomerIdAndStoreId(state, customerId, storeId));

  return (
    <Stack flex={1}>
      <Section title="Store Details" subtitle="Please view the below store details based on store selection" />
      <CommonBox>
        {customerId && storeId ? (
          store ? (
            <Grid spacing={1} container>
              {Object.entries(store).map(([key, value]) => {
                const storeAttribute = renderStoreDetailsConfig[key];
                return (
                  storeAttribute && (
                    <Grid item xs={12} key={key}>
                      <GridLabelValueUI
                        label={storeAttribute.label}
                        labelSx={{ "&.MuiTypography-root": { minWidth: "160px" } }}
                        valueSx={{ "&.MuiTypography-root": { minWidth: "160px" } }}
                      >
                        {storeAttribute.formatData?.(value) || value || storeAttribute.alternate}
                      </GridLabelValueUI>
                    </Grid>
                  )
                );
              })}
            </Grid>
          ) : (
            <EmptyDataVisualComp src={no_content_img} />
          )
        ) : (
          <EmptyDataVisualComp
            src={no_content_img}
            altSrc="No Content"
            title="Input Pending From User"
            subTitle="Kindly choose the customer and store names to load the store details"
          />
        )}
      </CommonBox>
    </Stack>
  );
};


const EquipmentDetails = () => {
  // Get the array of selected equipment IDs (or an empty array)
  const equipmentIds =
    useSelector((state) => selectTaskFieldValue(state, TASK_FIELDS.EQUIPMENT.ID)) || [];

  // State to keep track of which equipment's details to display
  const [currentIndex, setCurrentIndex] = useState(0);

  // Get the current equipment ID and its details
  const currentEquipmentId = equipmentIds[currentIndex];
  const equipmentDetails = useSelector((state) =>
    currentEquipmentId ? findEquipmentById(state, currentEquipmentId) : null
  );

  return (
    <Stack flex={1}>
      <Section
        title="Equipment Details"
        subtitle="Please view the below equipment details based on equipment selection"
      />
      <CommonBox>
        {equipmentIds.length > 0 ? (
          equipmentDetails ? (
            <Box>
              <Grid container spacing={1}>
                {Object.entries(equipmentDetails).map(([key, value]) => {
                  const equipmentAttribute = renderEquipmentDetailsConfig[key];
                  return (
                    equipmentAttribute && (
                      <Grid item xs={12} key={key}>
                        <GridLabelValueUI
                          label={equipmentAttribute.label}
                          labelSx={{ "&.MuiTypography-root": { minWidth: "160px" } }}
                          valueSx={{ "&.MuiTypography-root": { minWidth: "160px" } }}
                        >
                          {equipmentAttribute.formatData?.(value) ||
                            value ||
                            equipmentAttribute.alternate}
                        </GridLabelValueUI>
                      </Grid>
                    )
                  );
                })}
              </Grid>

              {/* Navigation buttons are shown only if there is more than one equipment */}
              {equipmentIds.length > 1 && (
                <Box display="flex" justifyContent="space-between" mt={2}>
                  <Button
                    variant="outlined"
                    onClick={() =>
                      setCurrentIndex((prevIndex) =>
                        prevIndex === 0 ? equipmentIds.length - 1 : prevIndex - 1
                      )
                    }
                  >
                    Previous
                  </Button>
                  <Typography variant="subtitle2" sx={{ alignSelf: "center" }}>
                    {`Equipment ${currentIndex + 1} of ${equipmentIds.length}`}
                  </Typography>
                  <Button
                    variant="outlined"
                    onClick={() =>
                      setCurrentIndex((prevIndex) =>
                        prevIndex === equipmentIds.length - 1 ? 0 : prevIndex + 1
                      )
                    }
                  >
                    Next
                  </Button>
                </Box>
              )}
            </Box>
          ) : (
            <EmptyDataVisualComp src={no_content_img} />
          )
        ) : (
          <EmptyDataVisualComp
            src={no_content_img}
            altSrc="No Content"
            title="Input Pending From User"
            subTitle="Kindly choose the equipment ID to load the equipment details"
          />
        )}
      </CommonBox>
    </Stack>
  );
};

const SubmitFormButton = () => {
  const areRequiredFieldsFilled = useSelector(selectAreRequiredTaskFieldsFilled);
  const fieldData = useSelector(selectAllTasksFieldsWithId);
  const { triggerMutation: createTask, isLoading, data, isError, error } = useCreateTaskMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (data) {
      dispatch(setAlert(`Task Creation Successful`, "Tasks with task title and Id was successfully created", SUCCESS));
      const task_id = data.data?.task_data?.task_id;
      navigate(task_id ? routeToSingleTask(task_id) : PRIVATE_ROUTES.tasks);
    }
  }, [dispatch, navigate, data]);

  useEffect(() => {
    if (isError) {
      const serviceUnavailable = error.status === 503;
      dispatch(
        setAlert(serviceUnavailable ? error.data.title : "Task Creation Error Occurred", error.data.message, serviceUnavailable ? ERROR : WARNING)
      );
    }
  }, [dispatch, isError, error]);

  const onSubmitForm = (e) => {
    e.preventDefault();
    createTask(fieldData);
  };

  return (
    <Fab
      onClick={onSubmitForm}
      disabled={isLoading || !areRequiredFieldsFilled}
      variant="extended"
      color="primary"
      type="submit"
      form="add-new-task-form"
      sx={{
        position: "fixed",
        bottom: "24px",
        right: "24px",
        fontSize: "14px",
        borderRadius: "0 20px",
        fontWeight: 600,
        minWidth: "170px",
      }}
    >
      {isLoading ? (
        <LoadingProgress size={24} />
      ) : (
        <Fragment>
          Create Task
          <DoubleArrow sx={{ ml: 1 }} />
        </Fragment>
      )}
    </Fab>
  );
};

export default function NewTaskForm() {
  return (
    <Box sx={{ p: 3, flex: 1, overflowY: "auto" }}>
      <Stack gap={3} direction="row" flexWrap="wrap" alignItems="flex-start">
        <NewTaskFormComponent />
        <Stack flex={1}>
          <StoreDetails />
          <EquipmentDetails />
        </Stack>
        <SubmitFormButton />
      </Stack>
    </Box>
  );
}
